<template>
	<shutter-panel ref="shutterPanel" />
</template>


<script type="text/javascript">
    import Shutter from "@/mixins/Shutter.js"
    import ShutterActs from '@/mixins/shutters-manager/Acts.js'

	export default {
		name: "acteListe",
		mixins: [
            Shutter, 
            ShutterActs,
        ],
		props: [
            'horse_id'
        ],
		mounted() {
            this.init_component()
		},
		methods: {
			init_component() {
				this.setupHorseAct(parseInt(this.horse_id))
			}
        }
	}
</script>
